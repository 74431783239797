import { useAmSize } from 'src/modules/address-management';
import useThemeOld from 'src/services/theme/useThemeOld';
import AddressManagementBar from '../../modules/address-management/components/AddressManagementBar/AddressManagementBar';
import { sendDeliveryAddressPopupShow } from '../../modules/address-management/helpers/AddressManagementAnalytics';
import useModals from '../../modules/modals/useModals';
import RetailsInfo from '../../modules/retails/components/RetailsInfo/RetailsInfo';
import { sendChainCardClick } from '../../modules/retails/helpers/retailsInfoAnalytics';
import getStyles from './HomeRetails.styles';

const HomeRetails = () => {
  const modals = useModals();
  const componentsSize = useAmSize();
  const theme = useThemeOld();
  const styles = getStyles(theme);
  const place = 'main';

  const handleAmButtonClick = () => {
    modals.openModal('addressManagement');
    sendDeliveryAddressPopupShow(place);
  };

  const handleRetailInfoCardClick = (isAvailable: boolean) => {
    sendChainCardClick(place, isAvailable);
  };

  return (
    <div className="HomeRetails">
      <div className="HomeRetails__container">
        <AddressManagementBar
          withIconAnimation
          size={componentsSize}
          onAmButtonClick={handleAmButtonClick}
        />

        <RetailsInfo
          size={componentsSize}
          onClickOpenAm={ handleAmButtonClick }
          onRetailInfoCardClick={handleRetailInfoCardClick}
        />
      </div>

      <style jsx>{styles}</style>
    </div>
  );
};

export default HomeRetails;
