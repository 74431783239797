import css from 'styled-jsx/css';
import { phone, desktop } from '../../styles/media';
import { ThemeOld } from 'src/styles/theme-old';

const getStyles = (theme: ThemeOld) => {
  return css`
  .HomeSlider__placeholder {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: ${theme.colors.solitude};
    z-index: 3;
  }
  .HomeSlider {
    max-width: 1136px;
    max-height: 356px;
    margin: 80px 32px;
    position: relative;
  }
  .HomeSlider__slide {
    position: relative;
    line-height: 0;
    width: 100%;
    flex-shrink: 0;
    cursor: pointer;
  }
  .HomeSlider__slideImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media ${desktop} {
    .HomeSlider {
      height: 356px;
      margin: 80px auto;
      overflow: hidden;
    }
  }

  @media ${phone} {
    .HomeSlider {
      width: 100%;
      max-height: initial;
      margin: 14px 0 48px;
    }
  }
  `;
};

export default getStyles;
