import css from 'styled-jsx/css';
import { desktop } from '../../styles/media';

const getStyles = theme => {
  return css`

    .SeeMoreCard {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      background-color: ${ theme.colors.white };
      text-decoration: none;
    }

    .SeeMoreCard_img {
      margin-bottom: 12px;
      height: 40px;
      width: 40px;

      border-radius: 50%;
      border: 1px solid ${ theme.colors.whiteSmoke };
      transition: 0.2s ease-in-out;

      background-image: url(/static/images/seeMoreLogo.svg);
      background-repeat: no-repeat;
      background-size: 12px 12px;
      background-position: center center;
    }

    .SeeMoreCard__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }


    @media ${ desktop } {
      .SeeMoreCard:hover .SeeMoreCard_img {
        border: 1px solid ${ theme.colors.mainBrand };
      }
    }

  `;
};

export default getStyles;
