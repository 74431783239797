import { ReactNode } from "react";
import StylishBox from "../StylishBox/StylishBox";
import { StylishBoxStyles } from "../StylishBox/types";

interface ContainerProps {
  children: ReactNode;
  sbs?: StylishBoxStyles;
}

const Container = (props: ContainerProps) => {
  const { children, sbs } = props;

  return (
    <StylishBox
      sbs={{
        ...sbs,
        mx: 'auto',
        px: {
          tablet: '32px',
          phone: '16px',
        },
        maxWidth: {
          desktop: '1136px',
          tablet: '960px',
          phone: '432px',
        },
      }}
    >
      {children}
    </StylishBox>
  );
};

export default Container;
