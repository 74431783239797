import cn from 'classnames';
import { CURRENCY } from 'src/data/constants';
import { Unit } from '../api/types';
import { useLocalize } from '../services/localize/useLocalize';
import { Typography } from '../ui-kit/Typography';
import formatWeight from '../utils/products/formatWeight';
import UnoptimizedImage from './UnoptimizedImage/UnoptimizedImage';

interface Props {
  webUrl: string;
  img: string;
  title: string;
  price: string;
  oldPrice: string;
  unit?: Unit;
  weight: number;
  volume: number;
  isHit?: boolean;
  discount?: number;
  customBadge: string | null;
}

function ProductTile(props: Props) {
  const localize = useLocalize();

  const currencyText = localize(`general.currency.${CURRENCY}`);

  return (
    <a className="ProductTile"
      href={props.webUrl} target="_blank" rel="noreferrer">
      <div className="ProductTile__imageContainer">
        <UnoptimizedImage
          className="ProductTile__image"
          src={props.img}
          alt={props.title}
          width={182}
          height={182}
        />
      </div>

      <div className="ProductTile__infoContainer">
        <div className="ProductTile__prices">
          <Typography
            element='span'
            variant='text3'
            color='error'
            sbs={{ pr: '15px' }}
            dataMarker='Price'
          >
            {`${props.price} ${currencyText}`}
          </Typography>
          <Typography
            element='span'
            variant='text3'
            color='gray400'
            sbs={{ textDecoration: 'line-through'}}
            dataMarker='Old price'
          >
            {`${props.oldPrice} ${currencyText}`}
          </Typography>
        </div>

        <div className="ProductTile__titleWrapper">
          <Typography
            element='h3'
            variant='text6'
            dataMarker='Product Title'
          >{props.title}</Typography>
          {props.unit &&
            <Typography element='h3' variant='text6' color='gray400' dataMarker='Weight'>
              {formatWeight(props.unit, props.weight, props.volume, localize)}
            </Typography>
          }
        </div>

        <div className="ProductTile__badgeContainer">
          { props.isHit &&
              <div className='ProductTile__badge'>
                <div className="ProductTile__hitBadge">
                  {localize('product_hit')}
                </div>
              </div>
          }
          { props.discount &&
              <div className='ProductTile__badge'>
                <div className="ProductTile__discountBadge" data-marker="Discount">
                  {`-${props.discount} %`}
                </div>
              </div>
          }
          { props.customBadge &&
              <div className='ProductTile__badge'>
                <div className={cn(
                  'Badge_ownBrand', {
                  [`Badge_ownBrand_${props.customBadge}`]: !!props.customBadge,
                })}></div>
              </div>
          }
        </div>

      </div>

      <style jsx>{`
        .ProductTile {
          padding: 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          background: #FFFFFF;
          height: 345px;
          position: relative;
          cursor: pointer;
          text-decoration: none;
        }

        .ProductTile__imageContainer {
          width: 182px;
          height: 182px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .ProductTile__image {
          max-width: 100%;
          max-height: 100%;
        }

        .ProductTile__infoContainer {
          width: 100%;
          height: 148px;
          padding-top: 15px;
          display: flex;
          flex-direction: column;
        }

        .ProductTile__prices {
          display: flex;
          flex-wrap: wrap;
        }

        .ProductTile__titleWrapper {
          height: 100%;
          justify-content: space-between;
          display: flex;
          flex-direction: column;
        }

        .ProductTile__badgeContainer {
          position: absolute;
          top: 16px;
          left: 0;
          min-width: 48px;
          display: flex;
          flex-direction: column;
        }

        .ProductTile__badge {
          margin-bottom: 5px;
        }

        .ProductTile__hitBadge,
        .ProductTile__discountBadge {
          font-family: "Mulish", Verdana, Arial, sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 13px;
          border: 2px solid white;
          border-radius: 0px 16px 16px 0px;
          height: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .ProductTile__hitBadge {
          letter-spacing: 1.1px;
          text-transform: uppercase;
          color: #404844;
          background: #FFCF1B;
          margin-bottom: 6px;
        }

        .ProductTile__discountBadge {
          color: #FFFFFF;
          background: #FF5252;
        }

        .Badge_ownBrand {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          background-repeat: no-repeat;
          background-position: center center;
          margin-left: 6px;
          border: 2px solid white;
        }

        .Badge_ownBrand_green-bird {
          background-image: url('/static/images/auchan_birds/green.svg');
        }

        .Badge_ownBrand_red-bird {
          background-image: url('/static/images/auchan_birds/red.svg');
        }

        .Badge_ownBrand_black-bird {
          background-image: url('/static/images/auchan_birds/black.svg');
        }
      `}</style>
    </a>
  );
}

export default ProductTile;
