import classNames from 'classnames';
import Link from 'next/link';
import { Fragment, ReactChild } from 'react';
import useThemeOld from '../services/theme/useThemeOld';

type ButtonSize = 'small' | 'normal';
type ButtonType = 'button' | 'submit' | 'reset';

interface Props {
  text: ReactChild;
  size: ButtonSize;
  width?: string;
  withBorder?: boolean;
  filled?: boolean;
  type?: ButtonType;
  radiusBorder?: string;
  onClick?: () => void;
  nextLinkHref?: string;
  dataMarkerValue?: string;
}

/**
 * @deprecated Please use {@link 'src/Component/Button/Button.ts'} instead.
 */
function Button(props: Props) {
  const theme = useThemeOld();

  const {
    size,
    withBorder,
    type = 'button',
    nextLinkHref,
    filled,
    dataMarkerValue,
  } = props;

  return (
    <Fragment>
      {nextLinkHref &&
        <Link href={nextLinkHref} legacyBehavior>
          <a
            className={classNames(
              'Button',{
              [`Button_${size}`]: size,
              'Button_withBorder': withBorder,
            })}
            data-marker={dataMarkerValue}
          >
            {props.text}
          </a>
        </Link>
      }
      {!nextLinkHref &&
        <button
          className={classNames(
            'Button',{
            [`Button_${size}`]: size,
            'Button_withBorder': withBorder,
            'Button_filled': filled,
          })}
          type={type}
          onClick={props.onClick}
          data-marker={dataMarkerValue}
        >
          {props.text}
        </button>
      }
        <style jsx>{`
          .Button {
            font-family: ${theme.fonts.default};
            font-weight: 600;
            color: ${theme.colors.mainBrand};
            border: 0;
            background: transparent;
            ${props.width ? `width: ${props.width};` : ''}
            padding: 0;
            cursor: pointer;
            display: block;
            text-align: center;
            text-decoration: none;
          }
          .Button_small {
            font-size: 14px;
            line-height: 17px;
            padding: 10px 20px;
          }
          .Button_normal {
            font-size: 16px;
            line-height: 44px;
            padding: 0 24px;
          }
          .Button_withBorder {
            border: 1px solid ${theme.colors.mainBrand};
            ${props.radiusBorder ? `border-radius: ${props.radiusBorder};` : ""}
            transition: 0.2s color ease-in-out, 0.2s background ease-in-out;
          }
          .Button_withBorder:hover {
            background-color: ${theme.colors.hoverBrand};
            border-color: ${theme.colors.hoverBrand};
            color: #fff;
          }
          .Button_withBorder:active {
            border-color: ${theme.colors.activeBrand};
            background-color: ${theme.colors.activeBrand};
            border-color: ${theme.colors.activeBrand};
            color: ${theme.colors.white};
            outline: none;
          }
          .Button_filled {
            background-color: ${theme.colors.mainBrand};
            border-color: ${theme.colors.mainBrand};
            color: ${theme.colors.white};
          }
          .Button_filled:hover {
            background-color: ${theme.colors.hoverBrand};
            border-color: ${theme.colors.hoverBrand};
          }
          .Button_filled:active {
            border-color: ${theme.colors.activeBrand};
            background-color: ${theme.colors.activeBrand};
            border-color: ${theme.colors.activeBrand};
            outline: none;
          }
        `}</style>
    </Fragment>
  );
}

export default Button;
