import { phone, tablet } from 'src/styles/media';
import css from 'styled-jsx/css';

const getStyles = () => {
  return css`
    .NewYearAnimation {
      pointer-events: none;
    }
    .NewYearAnimation__image {
      position: absolute;
      z-index: 151;
      top: -80px;
      cursor: default;
      overflow: hidden;
      pointer-events: none;
      animation-name: NewYearAnimation-fall,NewYearAnimation-shake;
      animation-duration: 4s, 4s;
      animation-timing-function: linear, linear;
      animation-iteration-count: infinite, infinite;
      animation-play-state: running, running;
    }
    .NewYearAnimation_newVersion {
      animation-name: NewYearAnimation-fall-new-version, NewYearAnimation-shake;
      animation-duration: 4s, 4s;
      animation-timing-function: linear, linear;
      animation-iteration-count: infinite, infinite;
      animation-play-state: running, running;
    }

    .NewYearAnimation__image:nth-of-type(2n) img {
      animation: NewYearAnimation-spin 3s linear 0s infinite;
    }
    .NewYearAnimation__image:nth-of-type(2n+1) img {
      animation: NewYearAnimation-spin-revert 3s linear 0s infinite;
    }

    .NewYearAnimation__image:nth-of-type(1) {
      left: 40%;
      animation-delay: 1.5s, 0s;
    }
    .NewYearAnimation__image:nth-of-type(2) {
      left: 5%;
      animation-delay: 1.5s, 0s;
    }
    .NewYearAnimation__image:nth-of-type(3) {
      left: 43%;
      animation-delay: 1.8s, 0s;
    }
    .NewYearAnimation__image:nth-of-type(4) {
      left: 35%;
      animation-delay: .3s, 0s;
    }
    .NewYearAnimation__image:nth-of-type(5) {
      left: 23%;
      animation-delay: .7s, 0s;
    }
    .NewYearAnimation__image:nth-of-type(6) {
      left: 13%;
      animation-delay: 2.5s, 0s;
    }
    .NewYearAnimation__image:nth-of-type(7) {
      left: 50%;
      animation-delay: 2.5s, 0s;
    }
    .NewYearAnimation__image:nth-of-type(8) {
      left: 53%;
      animation-delay: .6s, 0s;
    }
    .NewYearAnimation__image:nth-of-type(9) {
      left: 27%;
      animation-delay: 1.8s, 0s;
    }
    .NewYearAnimation__image:nth-of-type(10) {
      left: 60%;
      animation-delay: 2s, 0s;
    }
    .NewYearAnimation__image:nth-of-type(11) {
      left: 69%;
      animation-delay: 3s, 2s;
    }
    .NewYearAnimation__image:nth-of-type(12) {
      left: 30%;
      animation-delay: 3s,0s;
    }
    .NewYearAnimation__image:nth-of-type(13) {
      left: 90%;
      animation-delay: 3s, 0s;
    }
    .NewYearAnimation__image:nth-of-type(14) {
      left: 72%;
      animation-delay: .4s, 0s;
    }
    .NewYearAnimation__image:nth-of-type(15) {
      left: 80%;
      animation-delay: 1.5s, 0s;
    }

    .NewYearAnimation_stop {
      opacity: 0;
      transition: opacity 3s linear;
    }

    .NewYearAnimation_remove {
      display: none;
    }

    @keyframes NewYearAnimation-fall {
      0% {
        top: 0%;
      }
      100% {
        top: 80vh;
      }
    }

    @keyframes NewYearAnimation-fall-new-version {
      0% {
        top: 0%;
      }
      100% {
        top: 50vh;
      }
    }

    @keyframes NewYearAnimation-shake {
      0%{
        transform:translateX(0px)
      }
      50%{
        transform:translateX(10px)
      }
      100%{
        transform:translateX(0px)
      }
    }

    @keyframes NewYearAnimation-spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    @keyframes NewYearAnimation-spin-revert {
      from {
        transform: rotate(-0deg);
      }
      to {
        transform: rotate(-360deg);
      }
    }

    @media ${tablet} {
      .NewYearAnimation__image,
      .NewYearAnimation_newVersion {
      animation-duration: 5s, 4s;
      }
      .NewYearAnimation__image:nth-of-type(6) {
        left: 10%;
        animation-delay: 2.5s, 0s;
      }
      .NewYearAnimation__image:nth-of-type(3) {
        left: 65%;
        animation-delay: 1.2s, 0s;
      }
      .NewYearAnimation__image:nth-of-type(11) {
        left: 73%;
        animation-delay: 2.3s, 2s;
      }
    }

    @media ${phone} {
      .NewYearAnimation__image,
      .NewYearAnimation_newVersion {
        animation-duration: 6s, 4s;
      }
      .NewYearAnimation__image:nth-of-type(4) {
        left: 25%;
      }
      .NewYearAnimation__image:nth-of-type(6) {
        left: 3%;
        animation-delay: 0s, 0s;
      }
      .NewYearAnimation__image:nth-of-type(9) {
        left: 50%;
        animation-delay: .7s, 0s;
      }
      .NewYearAnimation__image:nth-of-type(10) {
        left: 60%;
      }
      .NewYearAnimation__image:nth-of-type(12) {
        left: 20%;
      }
      .NewYearAnimation__image:nth-of-type(3),
      .NewYearAnimation__image:nth-of-type(5),
      .NewYearAnimation__image:nth-of-type(8),
      .NewYearAnimation__image:nth-of-type(13),
      .NewYearAnimation__image:nth-of-type(14),
      .NewYearAnimation__image:nth-of-type(15) {
        display: none;
      }
    }

  `;
};

export default getStyles;
