import Link from 'next/link';
import { ReactChild } from 'react';
import useThemeOld from 'src/services/theme/useThemeOld';
import { Typography } from '../../ui-kit/Typography';
import getStyles from './SeeMoreCard.styles';
interface Props {
  linkHref: string;
  linkText: ReactChild;
  isNextLink?: boolean;
  dataMarker?: string;
}

const SeeMoreCard = (props: Props) => {
  const {
    linkHref,
    linkText,
    isNextLink = false,
    dataMarker = 'SeeMoreCard',
  } = props;

  const theme = useThemeOld();
  const styles = getStyles(theme);

  if (isNextLink) {
    return (
      <Link href={linkHref} legacyBehavior>
        <a className='SeeMoreCard' data-marker={dataMarker}>
          <div className='SeeMoreCard__content'>
            <div className='SeeMoreCard_img'/>
            <Typography element='span' variant='text3' color='primary'>{linkText}</Typography>
          </div>
          <style jsx>{styles}</style>
        </a>
      </Link>
    );
  }

  return (
    <a
      className='SeeMoreCard'
      target='_blank'
      href={linkHref}
      rel='noreferrer'
      data-marker={dataMarker}
    >
      <div className='SeeMoreCard__content'>
        <div className='SeeMoreCard_img'/>
        <Typography element='span' variant='text3' color='primary'>{linkText}</Typography>
      </div>
      <style jsx>{styles}</style>
    </a>
  );
};

export default SeeMoreCard;
