import React from 'react';
import Head from 'next/head';

interface Props {
  title: string;
  description: string;
  canonical?: string;
}

const MainSiteMeta = ({ title, description, canonical }: Props) => (
  <Head>
    <title key="title">{title}</title>
    <meta name="description" content={description} key="meta-description" />
    {
      canonical && <link rel='canonical' href={canonical} />
    }
  </Head>
);

export default MainSiteMeta;
