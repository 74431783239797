import css from 'styled-jsx/css';

const getStyles = () => {
  return css`
    .Benefit {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .Benefit__iconWrap {
      display: flex;
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      text-align: center;
    }
  `;
};

export default getStyles;
