import IconFont from '../../ui-kit/IconFont';
import { Typography } from '../../ui-kit/Typography';
import getStyles from './Benefit.styles';

interface BenefitProps {
  iconName: 'basket' | 'price' | 'fresh' | 'clock';
  text: string;
}

const Benefit = (props: BenefitProps) => {
  const {
    iconName,
    text,
  } = props;
  const styles = getStyles();

  return (
    <div className='Benefit'>
      <span className='Benefit__iconWrap'>
        <IconFont name={iconName} size='16px' color='gray500' sbs={{m: 'auto'}}/>
      </span>

      <Typography variant='text6'>{text}</Typography>
      <style jsx>{styles}</style>
    </div>
  );
};

export default Benefit;
