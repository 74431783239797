
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import getStyles from "./NewYearAnimation.styles";

const snows = [
  '../../../static/images/newYear/Snow1.svg',
  '../../../static/images/newYear/Snow2.svg',
  '../../../static/images/newYear/Snow3.svg',
  '../../../static/images/newYear/Snow4.svg',
  '../../../static/images/newYear/Snow5.svg',
  '../../../static/images/newYear/Snow6.svg',
  '../../../static/images/newYear/Snow7.svg',
  '../../../static/images/newYear/Snow8.svg',
  '../../../static/images/newYear/Snow2.svg',
  '../../../static/images/newYear/Snow2.svg',
  '../../../static/images/newYear/Snow5.svg',
  '../../../static/images/newYear/Snow1.svg',
  '../../../static/images/newYear/Snow7.svg',
  '../../../static/images/newYear/Snow8.svg',
  '../../../static/images/newYear/Snow2.svg',
];

const NewYearAnimation = () => {
  const [isShowAnimation, setShowAnimation] = useState(true);
  const [isRemoveAnimation, setRemoveAnimation] = useState(false);
  const styles = getStyles();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAnimation(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const handleRemoveAnimation = (event) => {
    if (event.type === 'transitionend') {
      setRemoveAnimation(true);
    }
  };

  if (!isShowAnimation) {
    document.getElementById("snowAnimation")!
      .addEventListener("transitionend", handleRemoveAnimation);
  }

  return (
    <div className='NewYearAnimation'>
      {snows.map((snow, index) => (
        <div
          key={index}
          className={classNames(
            'NewYearAnimation__image NewYearAnimation_newVersion', {
            'NewYearAnimation_stop': !isShowAnimation,
            'NewYearAnimation_remove': isRemoveAnimation,
          })}
          id='snowAnimation'
          data-marker={`snowflake ${index}`}
        >
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src={snow} alt='snow' />
        </div>
      ))}

      <style jsx>{styles}</style>
    </div>
  );
};
export default NewYearAnimation;
