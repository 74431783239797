import React from 'react';
import { ThemeOld } from '../styles/theme-old';
import { Typography } from '../ui-kit/Typography';

interface Props {
  smallHeading: string;
  bigHeading: string;
  theme: ThemeOld;
}

function DoubleHeading(props: Props) {
  return (
    <div className="DoubleHeading">
      <Typography
        element='h3'
        variant='caption2'
        color='primary'
        dataMarker='Small Heading'
      >
        {props.smallHeading}
      </Typography>

      <Typography
        variant='h2'
        dataMarker='Big Heading'
      >
        {props.bigHeading}
      </Typography>

      <style jsx>{`
        .DoubleHeading {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      `}</style>
    </div>
  );
}

export default DoubleHeading;
