import React, { ReactNode, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

interface Props {
  onImpressed: () => void;
  children: ReactNode;
  partialVisibility?: boolean;
}

const Impressionable = (props: Props) => {
  const [isImpressed, setImpressed] = useState(false);

  const handleChange = (isVisible: boolean) => {
    if (isVisible && !isImpressed) {
      props.onImpressed();
      setImpressed(true);
    }
  };

  return (
    <VisibilitySensor
      partialVisibility={props.partialVisibility}
      onChange={handleChange}
    >
      {props.children}
    </VisibilitySensor>
  );
};

export default Impressionable;
